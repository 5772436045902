import { requestWithoutToken, requestWithToken } from "../helpers/helpers";
import { AUTH_USER, AUTH_USER_FAIL, LOGIN_SUCCESS, LOGOUT } from "./types";

// Authenticate User
export const authUser = () => async (dispatch) => {
  try {
    const response = await requestWithToken("GET", "/api/admin/me");

    dispatch({
      type: AUTH_USER,
      payload: response.data.data,
    });

    return response.data;
  } catch (err) {
    // dispatch(error(err));
    dispatch({ type: AUTH_USER_FAIL });

    return err.response.data;
  }
};

// Login User
export const phoneotp = async (phone) => {
  try {
    const body = { phone };
    const response = await requestWithoutToken("POST", "/api/admin/otp", body);

    return response.data;
  } catch (err) {
    return err.response.data;
    // dispatch(error(err));
  }
};
export const login = (phone, otp) => async (dispatch) => {
  try {
    const body = { phone, otp };

    const response = await requestWithoutToken(
      "POST",
      "/api/admin/signIn",
      body
    );

    if (response.status) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data.data,
      });
      return response.data;
    }
  } catch (err) {
    return err.response.data;
    // dispatch(error(err));
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
