import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import Content from "../Content/Content";
import WrapperOverlay from "./WrapperOverlay";
import HeaderRoutes from "../Header/HeaderRoutes";
import FooterRoutes from "../Footer/FooterRoutes";
import ThemeContext from "../../contexts/themeContext";
import { authUser } from "../../actions/auth";

export const WrapperContainer = ({ children, className, ...props }) => {
  const { rightPanel } = useContext(ThemeContext);
  return (
    <div
      className={classNames(
        "wrapper",
        { "wrapper-right-panel-active": rightPanel },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  );
};
WrapperContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
WrapperContainer.defaultProps = {
  className: null,
};

const Wrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUser = async () => {
      const response = await dispatch(authUser());
      if (!response.status) {
        navigate("/login");
      }
    };

    authenticateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WrapperContainer>
        <HeaderRoutes />
        <Content />
        <FooterRoutes />
      </WrapperContainer>
      <WrapperOverlay />
    </>
  );
};

export default Wrapper;
