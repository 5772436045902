import {
  AUTH_USER,
  AUTH_USER_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const initialState = {
  loading: true,
  authenticated: false,
  user: null,
};

// eslint-disable-next-line default-param-last
const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_USER:
      localStorage.setItem("user_data", JSON.stringify(payload));
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: payload,
      };
    case AUTH_USER_FAIL:
      return {
        ...state,
        loading: false,
        authenticated: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("auth-token", payload.token);
      localStorage.setItem("navigation", JSON.stringify(payload.navigation));
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: payload.user,
      };
    case LOGOUT:
      localStorage.removeItem("auth-token");
      localStorage.removeItem("navigation");
      localStorage.removeItem("user_data");
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export default auth;
